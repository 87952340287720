import React, {useContext, useState} from 'react';
import {
    CButton,
    CCol, CForm, CFormInput,
    CLoadingButton,
    CModal,
    CModalBody,
    CRow
} from "@coreui/react-pro";
import {useNavigate} from "react-router-dom";
import {z} from "zod";
import {SubmitHandler, useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import api from "../api";
import useToast from "../../hooks/toast";
import {GenericErrorHandler} from "../../helpers";
import { Context } from '../../context';

export function ModalLogin(){
    const { visibleModalLogin  } = useContext(Context)
    const [isIntro, setIsIntro] = useState(true)

    return(<CModal  className={'bg-light'} portal backdrop={'static'} size={isIntro ? 'lg' : 'sm'} alignment='center' visible={visibleModalLogin}>
        <CModalBody>
            {isIntro ? <Intro setIntro={setIsIntro}/> : <FormLogin/>}
        </CModalBody>
    </CModal>)
}

function Intro({setIntro} : {setIntro : React.Dispatch<React.SetStateAction<boolean>>}){

    return (<>
        <p className="h5">PENJELASAN DAN PERSETUJUAN</p>
        <ol>
            <li>Penelitian ini bertujuan untuk mengukur bagaimana perilaku CEO/CFO dalam kaitannya dengan domain kinerja
                keuangan perusahaan.
            </li>
            <li>Saya memahami bahwa partisipasi saya bersifat sukarela.</li>
            <li>Data yang diperoleh dari penelitian ini dijaga kerahasiaannya dan hanya digunakan untuk kepentingan
                ilmiah.
            </li>
        </ol>
        <p>Dengan mengklik “Setuju”, saya telah menyetujui pertimbangan-pertimbangan di atas dan bersedia untuk
            melanjutkan proses penelitian ini sampai dengan selesai.</p>
        <CButton onClick={() => {
            setIntro(false)
        }}>Saya Setuju</CButton>
    </>)
}

function FormLogin() {
    const navigate = useNavigate()
    const FormSchema = z.object({
        email: z.string().nullable().optional(),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
    })

    const [addToast] = useToast()
    const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
        await api.post(`/auth/token`, data).then(response => {
            localStorage.setItem('token', JSON.stringify(response.data))
            navigate(0)

        }).catch(reason => {
            GenericErrorHandler({reason: reason, addToast: addToast, additionalErrorHandler: (reason) => {
                    for (let item  in reason.response.data.errors) {
                        setError(item as ("email"), { type: 'custom', message: reason.response.data.errors[item][0] })
                    }
                }})
        });
    }

    return (<CForm
        id='loginform'
        onSubmit={handleSubmit(onSubmit)}
    >
        <p className={'text-center'}>Masukkan email untuk memulai</p>
        <div className='mb-3'>
            <CFormInput
                type='text'
                floatingLabel="Email"
                placeholder="Email"
                invalid={errors.email != null}
                feedbackInvalid={errors.email?.message}
                autoComplete={'username'}
                {...register("email")}
            />
        </div>

        <CRow>
            <CCol md={12} className='d-grid'>
                <CLoadingButton
                    size='lg'
                    type='submit'
                    form='loginform'
                    color='primary'
                    loading={isSubmitting}
                >
                    <span className='ms-2'>Mulai</span>
                </CLoadingButton>
            </CCol>
        </CRow>
    </CForm>)
}