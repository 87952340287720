
import { CCard, CCardBody, CCol, CContainer, CRow } from '@coreui/react-pro'
import {useEffect, useState} from 'react'
import FormLogin from "./componenet.form.login";

export default function Login() {

    const [txtYear, setTxtYear] = useState<string>()

    useEffect(() => {
        document.title = `Login Admin | ${process.env.REACT_APP_PUBLIC_SITE_NAME}`

        const envYear = process.env.REACT_APP_PUBLIC_COPYRIGHT_YEAR_FROM;
        const currentYear = (new Date().getFullYear()).toString();

        if(envYear === undefined || (envYear || "") === currentYear){
            setTxtYear(currentYear)
        }else{
            setTxtYear(`${envYear} - ${currentYear}`)
        }

    }, [])

    return (<div className="min-vh-100 d-flex flex-row align-items-center bg-dark-gradient">
        <CContainer>
            <CRow className="justify-content-center">
                <CCol md={4}>
                    <CCard className="p-4" color={"light-gradient"}>
                        <CCardBody>
                            <FormLogin/>
                        </CCardBody>
                    </CCard>
                    <div
                        className={`text-center mt-4 text-light`}
                    >
                        {`© ${txtYear} ${process.env.REACT_APP_PUBLIC_COPYRIGHT_NAME}`}
                    </div>
                </CCol>
            </CRow>
        </CContainer>
    </div>)
}
