import { useState } from "react";
import GeneralInformation from "./GeneralInforamation";
import {
    CButton,
    CCard,
    CCardBody,
    CCardTitle,
    CCol, 
    
    CRow
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { cilCaretLeft, cilCaretRight } from "@coreui/icons-pro";
import CasePart1 from "./CasePart1";
import CasePart2 from "./CasePart2";
import CasePart3 from "./CasePart3";


export default function Case() {
    const [page, setPage] = useState(1)

    return (<CCard>
        <CCardBody>
            <CCardTitle>
                <CRow>
                    <CCol>Case ({page}/4)</CCol>
                </CRow>
            </CCardTitle>
            {page === 1 && <GeneralInformation />}
            {page === 2 && <CasePart1 />}
            {page === 3 && <CasePart2 />}
            {page === 4 && <CasePart3 />}
            <div className={'d-flex justify-content-end mt-3'}>
                {page >= 2 && <CButton className={'ms-2'} onClick={() => {
                    setPage(x => {
                        const newpage = x - 1;
                        return newpage <= 1 ? 1 : newpage;
                    })
                }}><CIcon icon={cilCaretLeft} className="me-2" /> Sebelumnya</CButton>}
                {page < 4 && <CButton className={'ms-2'} onClick={() => {
                    setPage(x => {
                        const newpage = x + 1;
                        return newpage >= 5 ? 5 : newpage;
                    })
                }} >Selanjutnya <CIcon icon={cilCaretRight} className="ms-2" /></CButton>}
            </div>
        </CCardBody>

    </CCard>)
}

