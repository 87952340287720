import { useContext, useEffect, useState } from "react";
import { Context } from "../../../../../context";
import useToast from "../../../../../hooks/toast";
import { z } from "zod";
import {
    CAlert,
    CAlertHeading,
    CButton,
    CCard,
    CCardBody, 
    CCardTitle, CCol, CForm, CFormCheck, CLoadingButton, CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableRow
} from "@coreui/react-pro";
import Page1 from "./page1";
import Page2 from "./page2";
import Page3 from "./page3";
import Page4 from "./page4";
import Page5 from "./page5";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import CIcon from "@coreui/icons-react";
import { cilCaretLeft, cilCaretRight } from "@coreui/icons-pro";
import api from "../../../../api";
import { GenericErrorHandler } from "../../../../../helpers";


export default function MengukurKuesioner() {
    const [page, setPage] = useState(1)
    const [isDisableNext, setisDisableNext] = useState(false)
    const { reloadQuestionnaire } = useContext(Context);
    const [addToast] = useToast()
    const FormSchema = z.object({
        q1: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q2: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q3: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q4: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q5: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q6: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q7: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q8: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q9: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q10: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q11: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q12: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q13: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q14: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q15: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q16: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q17: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q18: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q19: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q20: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q21: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q22: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q23: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
        q24: z.string({ required_error: 'Pilih salah satu' }).min(1, 'Pilih salah satu'),
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        watch,
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
        defaultValues: { ...JSON.parse(localStorage.getItem('mengukurkuesioner') || "{}") }
    })

    const instance = watch()

    useEffect(() => {
        localStorage.setItem('mengukurkuesioner', JSON.stringify(instance))
        if (page === 1) {
            setisDisableNext(!instance.q1 || !instance.q2 || !instance.q3 || !instance.q4 || !instance.q5 || !instance.q6)
        } else if (page === 2) {
            setisDisableNext(!instance.q7 || !instance.q8 || !instance.q9)
        } else if (page === 3) {
            setisDisableNext(!instance.q10 || !instance.q11 || !instance.q12 || !instance.q13)
        } else if (page === 4) {
            setisDisableNext(!instance.q14 || !instance.q15 || !instance.q16 || !instance.q17 || !instance.q18)
        } else if (page === 5) {
            setisDisableNext(!instance.q19 || !instance.q20 || !instance.q21 || !instance.q22 || !instance.q23 || !instance.q24)
        } else {
            setisDisableNext(true)
        }
    }, [instance, page])


    function Question({ question, name }: { question: string, name: ("q1" | "q2" | "q3" | "q4" | "q5" | "q6" | "q7" | "q8" | "q9" | "q10" | "q11" | "q12" | "q13" | "q14" | "q15" | "q16" | "q17" | "q18" | "q19" | "q20" | "q21" | "q22" | "q23" | "q24") }) {
        return (<>
            <p>{question}</p>

            <CTable responsive>
                <CTableBody>
                    <CTableRow>
                        <CTableDataCell>
                            <CFormCheck
                                id={`${name}_1`}
                                invalid={errors[name] != null} feedbackInvalid={errors[name]?.message as string}
                                {...register(name, { required: true })}
                                type={"radio"}
                                value={1}
                                // style={{ borderColor: 'gray' }}
                                label={'1'}
                            // button={{ color: 'dark', variant: 'outline', size: 'sm' }}
                            />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormCheck
                                id={`${name}_2`}
                                invalid={errors[name] != null} feedbackInvalid={errors[name]?.message as string}
                                {...register(name, { required: true })}
                                type={"radio"}
                                value={2}
                                label={'2'}
                            // button={{ color: 'dark', variant: 'outline', size: 'sm' }}
                            />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormCheck
                                id={`${name}_3`}
                                invalid={errors[name] != null} feedbackInvalid={errors[name]?.message as string}
                                {...register(name, { required: true })}
                                type={"radio"}
                                value={3}
                                label={'3'}
                            // button={{ color: 'dark', variant: 'outline', size: 'sm' }}
                            />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormCheck
                                id={`${name}_4`}
                                invalid={errors[name] != null} feedbackInvalid={errors[name]?.message as string}
                                {...register(name, { required: true })}
                                type={"radio"}
                                value={4}
                                label={'4'}
                            // button={{ color: 'dark', variant: 'outline', size: 'sm' }}
                            />
                        </CTableDataCell>
                        <CTableDataCell>
                            <CFormCheck
                                id={`${name}_5`}
                                invalid={errors[name] != null} feedbackInvalid={errors[name]?.message as string}
                                {...register(name, { required: true })}
                                type={"radio"}
                                value={5}
                                label={'5'}
                            // button={{ color: 'dark', variant: 'outline', size: 'sm' }}

                            />
                        </CTableDataCell>
                    </CTableRow>
                    {/* <CTableRow>
                        <CTableDataCell style={{ fontSize: '11pt' }}>Sangat Setuju</CTableDataCell>
                        <CTableDataCell style={{ fontSize: '11pt' }}>Setuju</CTableDataCell>
                        <CTableDataCell style={{ fontSize: '11pt' }}>Netral</CTableDataCell>
                        <CTableDataCell style={{ fontSize: '11pt' }}>Tidak Setuju</CTableDataCell>
                        <CTableDataCell style={{ fontSize: '11pt' }}>Sangat Tidak Setuju</CTableDataCell>
                    </CTableRow> */}
                    {/*<CTableRow>*/}
                    {/*    <CTableDataCell colSpan={5} className={'is-invalid'}>*/}
                    {/*        <div className="invalid-feedback">Nama tidak boleh kosong</div>*/}
                    {/*    </CTableDataCell>*/}
                    {/*</CTableRow>*/}
                </CTableBody>
            </CTable>
        </>)
    }

    const onSubmit: SubmitHandler<FormSchemaType> = async () => {
        console.log(errors)

        await api.post('/answer', { section: 'mengukurkuesioner', answer: JSON.stringify(instance) }).then(() => {
            addToast({ message: 'Section submitted', color: 'success' })
            localStorage.removeItem('mengukurkuesioner')
            reloadQuestionnaire()
        }).catch(reason => {
            GenericErrorHandler({ reason, addToast })
        })
    }

    useEffect(() => {
        console.log(errors)
    }, [errors]);

    return (<>
        <CAlert color="info" dismissible>
            <CAlertHeading>Bobot Nilai</CAlertHeading>
            <ul style={{ paddingLeft: 'inherit', listStyleType: 'none' }}>
                <li>1: Sangat Setuju</li>
                <li>2: Setuju</li>
                <li>3: Netral</li>
                <li>4: Tidak Setuju</li>
                <li>5:  Sangat Tidak Setuju</li>
            </ul>
        </CAlert><CCard>
            <CCardBody>
                <CCardTitle>
                    <CRow>
                        <CCol>Kuesioner Penelitian ({page}/5)</CCol>

                    </CRow>
                </CCardTitle>
                <p>Pilihlah salah satu poin yang menyatakan keterwakilan pendapat anda terhadap setiap pernyataan
                    berikut.</p>
                <CForm
                    id='formSubmit'
                    className="row"
                    autoComplete='false'
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <CRow>
                        <CCol>
                            {page === 1 && <Page1 Question={Question} />}
                            {page === 2 && <Page2 Question={Question} />}
                            {page === 3 && <Page3 Question={Question} />}
                            {page === 4 && <Page4 Question={Question} />}
                            {page === 5 && <Page5 Question={Question} />}
                        </CCol>
                    </CRow>
                </CForm>
                <div className={'d-flex justify-content-end'}>
                    {page >= 2 && <CButton className={'ms-2'} onClick={() => {
                        setPage(x => {
                            const newpage = x - 1;
                            return newpage <= 1 ? 1 : newpage;
                        })
                    }}><CIcon icon={cilCaretLeft} className="me-2" /> Sebelumnya</CButton>}
                    {page < 5 && <CButton disabled={isDisableNext} className={'ms-2'} onClick={() => {
                        setPage(x => {
                            const newpage = x + 1;
                            return newpage >= 5 ? 5 : newpage;
                        })
                    }} >Selanjutnya <CIcon icon={cilCaretRight} className="ms-2" /></CButton>}
                    {page >= 5 && <CLoadingButton disabled={isSubmitting || isDisableNext} loading={isSubmitting} type={'submit'} form={'formSubmit'} className={'ms-2'}>Submit</CLoadingButton>}
                </div>
            </CCardBody>
        </CCard>
    </>)
}