import {useContext} from "react";
import {Context} from "../../../../../context";

export default function CasePart1(){
    const { user } = useContext(Context);

    return (<>
        {user?.respondent?.group === 1 && <Group1/>}
        {user?.respondent?.group === 2 && <Group2/>}
        {user?.respondent?.group === 3 && <Group3/>}
        {user?.respondent?.group === 4 && <Group4/>}
    </>)
}

function Group1(){

    return (<>
        <p>
            Di saat yang sama:
        </p>
        <ul>
            <li>Ad&At Bank memiliki aset sebidang tanah non-produktif seluas 10Ha yang telah dibeli sebelumnya di tahun
                2000 dengan satuan pembelian Rp120.000/m2. Pada tahun 2024, perusahaan merevaluasi tanah tersebut
                melalui jasa dari Kantor Jasa Penilai Publik (KJPP) yang dinilai secara independen. Hasil revaluasi aset
                tanah tersebut senilai Rp760.000/m2.
            </li>
            <li>Selain itu, salah satu perusahaan anak Ad&At Bank, Ad-At-Finance, berencana mengekspansi usahanya dengan
                membuka beberapa kantor cabang dan satu kantor wilayah di daerah yang sama dengan tanah non-produktif
                milik Ad&At Bank yang telah direvaluasi tersebut.
            </li>
        </ul>
    </>)
}

function Group2() {

    return (<>
        <p>
            Di saat yang sama:
        </p>
        <ul>
            <li>Ad&At Bank belum membayar iuran BPJS karyawan non-organiknya sejak tahun lalu (Juli), yang apabila
                dibayarkan dengan tahun ini (Juni) adalah sebesar Rp 1.352.000.000,- serta biaya asuransi purna
                jabatan Rp 2.450.000.000,-
            </li>
            <li>
                Di triwulan-I pihak manajemen hanya mencapai 0.5% dan 3%. Oleh karenanya, 
                mereka diminta untuk mengejar ketertinggalan capaian kemarin (2.5%) menjadi 5.5% dari total deposit di triwulan-II ini.
            </li>
            <li>Ad&At Bank juga baru saja menandatangani kontrak kerjasama dengan Pemerintah untuk menjadi pelopor
                literasi keuangan nasional selama 5 tahun ke depan (2024-2027). Nilai kontrak kerjasama ini senilai
                27 Miliar Rupiah, yang diterima tahun ini sebesar 5.4 Miliar.
            </li>
        </ul>
    </>)
}

function Group3() {

    return (<>
        <p>
            Di saat yang sama:
        </p>
        <ul>
            <li>Ad&At Bank juga baru saja menandatangani kontrak kerjasama dengan Pemerintah untuk menjadi role
                model dalam hal bank digitasi kepada masyarakat selama 3 tahun ke depan. Nilai kontrak kerjasama
                ini senilai 15 Miliar Rupiah dengan penerimaan up-front fee sebesar 20% dari nilai kontrak, sisanya
                dibayarkan setiap tahun sebesar 40%.
            </li>
            <li>Ad&At Bank juga belum membayarkan insentif bonus kepada para top-middle-down management dan karyawan
                selama 2 tahun terakhir karena pengalihan ke laba ditahan dan pengembangan usaha, sebesar Rp
                9.000.000.000,-.
            </li>
        </ul>
    </>)
}

function Group4() {

    return (<>
        <p>
            Di saat yang sama:
        </p>
        <ul>
            <li>Ad&At Bank juga baru saja menandatangani kontrak kerjasama dan pihak berelasi untuk penyediaan
                fasilitas pengelolaan kredit Perumahan JOGVIEW dengan mekanisme sharing profit sebesar 2% dari total
                plafon kredit 600 Miliar Rupiah. Kontrak kerjasama ini disepakati berjalan selama 15 tahun ke depan.
            </li>
            <li>Ad&At Bank juga belum membayar tagihan pembayaran jasa outsourcing kepada PT. Swadharma Sarana
                Pelayanan dari awal tahun ini dengan nilai Rp1.645.000.000
            </li>
            <li>Juga, biaya BPJS karyawan non-organik dari Ad&At Bank belum terbayarkan sejak tahun lalu (Juli), yang
                apabila dibayarkan dengan tahun ini (Juni) adalah sebesar Rp 945.124.000,-
            </li>
            <li>Pihak manajemen juga sampai triwulan ke-dua ini termasuk dua tahun berturut-turut belum mencatatkan laba
                yang lebih tinggi dari 3%.
            </li>
        </ul>
    </>)
}