
import {
    CButton,
    CCollapse,
    CContainer,
    CHeader,
    CLoadingButton,
    CModal,
    CModalBody,
    CModalFooter,
    CNavbar,
    CNavbarBrand,
    CNavbarNav,
    CNavbarToggler,
    CNavItem,
    CNavLink,
} from '@coreui/react-pro'
import React, {useState} from 'react'
import {cilAccountLogout, cilCloudDownload} from '@coreui/icons-pro'

import {Link, useNavigate} from "react-router-dom";
import useToast from "../../hooks/toast";
import CIcon from "@coreui/icons-react";
import api from "../api";

const Header = () => {
    const [showModalLogout, setshowModalLogout,] = useState(false)
    const [visible, setVisible] = useState(false)

    return (
        <CHeader position="sticky" className="mb-4">
            <CNavbar expand="md" className="w-100">
                <CContainer fluid>
                    <CNavbarBrand href="/admin">ADMIN</CNavbarBrand>
                    <CNavbarToggler
                        aria-label="Toggle navigation"
                        aria-expanded={visible}
                        onClick={() => setVisible(!visible)}
                    />
                    <CCollapse className="navbar-collapse" visible={visible}>
                        <CNavbarNav  className="me-auto">
                            <CNavItem>
                                <Link className={"nav-link"} to={{pathname : '/admin/respondent'}}>Respondent</Link>
                            </CNavItem>
                            <CNavItem>
                                <Link className={"nav-link"} to={{pathname : '/admin/backup'}} >
                                    <CIcon icon={cilCloudDownload} className={'me-2'} />Backup
                                </Link>
                            </CNavItem>
                        </CNavbarNav>
                        <CNavbarNav className="d-flex">
                            <CNavItem>
                                <CNavLink
                                    className='text-danger'
                                    style={{ cursor: 'pointer' }}
                                    onClick={e => {
                                        setshowModalLogout(!showModalLogout)
                                    }}
                                >
                                    <CIcon icon={cilAccountLogout} className="me-2" />
                                    Keluar
                                </CNavLink>
                            </CNavItem>
                        </CNavbarNav>
                    </CCollapse>

                </CContainer>
            </CNavbar>
            <ModalLogout
                visible={showModalLogout}
                setVisible={setshowModalLogout}
            />
        </CHeader>
    )
}

function ModalLogout({ visible, setVisible }: { visible: boolean, setVisible: (x: boolean) => void }) {
    const navigate = useNavigate()
    const [addToast] = useToast()
    const [loading, setLoading] = useState(false)

    return (
        <CModal portal backdrop={true} size='sm' alignment='center' visible={visible} onClose={() => { setVisible(false) }}>
            <CModalBody>
                <>Anda yakin ingin keluar?</>
            </CModalBody>
            <CModalFooter>
                <CButton disabled={loading} color="secondary" onClick={() => { setVisible(false) }}>
                    Tidak
                </CButton>
                <CLoadingButton loading={loading} color='danger'
                    onClick={() => {
                        setLoading(true)
                        api.get(`/auth/logout`).then(onfulfilled => {

                        }).finally(() => {
                            addToast({ color: "success", message: "Logout berhasil" })
                            localStorage.clear()
                            setVisible(false)
                            setLoading(false)
                            navigate(0)
                        })
                    }}
                >
                    Ya, Keluar!
                </CLoadingButton>
            </CModalFooter>
        </CModal>
    )

}

export default React.memo(Header)