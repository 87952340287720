
import {Route} from "react-router-dom";
import React from "react";
import Error404 from "./Error404";
import App from "./App";
import {IRoute} from "../types/layout";
import { Questionnaire } from "./pages/questionnaire";


const routes: IRoute[] = [
    { path: '/', name: 'Questionnaire ', element: <Questionnaire /> },
]

const ClientRoutes = [
    <Route path="/" element={
        <React.StrictMode><App /></React.StrictMode>}>
        {routes.map((route, key) => {
            return (route.element && <Route
                key={key}
                path={route.path}
                element={route.element}
            />)
        })}
        <Route path="*" element={<Error404 />} />
    </Route>
];

export default ClientRoutes