
import { CAlert, CCol, CForm, CFormInput, CImage, CLoadingButton, CRow } from '@coreui/react-pro'
import logoImage from '../../logo.svg'
import { z } from 'zod';
import { useForm, SubmitHandler } from "react-hook-form"
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearchParams } from 'react-router-dom'
import api from "../api";

export default function FormLogin() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()


    const FormSchema = z.object({
        email: z.string().nullable().optional(),
        password: z.string().nullable().optional(),
        message: z.void()
    });

    type FormSchemaType = z.infer<typeof FormSchema>;

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setError,
    } = useForm<FormSchemaType>({
        resolver: zodResolver(FormSchema),
    })

    const onSubmit: SubmitHandler<FormSchemaType> = async (data) => {
        await api.post(`/token`, data).then(response => {
            localStorage.setItem('tokenadmin', response.data)
            navigate(searchParams.get("returnUrl") || "/admin")
        }).catch(reason => {
            if (reason.code === "ERR_NETWORK") {
                setError('message', {type: 'custom', message: reason.message})
            } else {
                const dataError = reason.response.data.errors;
                if(dataError)
                    for (let item in dataError)
                        setError(item as ("email" | "password"), { type: 'custom', message: dataError[item] })
                else
                    setError('message', {type: 'custom', message : reason.response.data.message || "Undefined error"})
            }
        });
    }


    return (<CForm
        id='loginform'
        onSubmit={handleSubmit(onSubmit)}
    >
        {errors.message &&
            <CAlert color="danger" variant='solid' dismissible>
                {errors.message.message}
            </CAlert>
        }
        <div className="text-center mb-2">
            <CImage height={100} src={logoImage}/>
        </div>
        <div className='mb-3'>
            <CFormInput
                type='text'
                floatingLabel="Email"
                placeholder="Email"
                invalid={errors.email != null}
                feedbackInvalid={errors.email?.message}
                autoComplete={"off"}
                {...register("email")}
            />
        </div>
        <div className='mb-3'>
            <CFormInput
                type='password'
                floatingLabel="Password"
                placeholder="Password"
                invalid={errors.password != null}
                feedbackInvalid={errors.password?.message}
                autoComplete={"off"}
                {...register("password")}
            />
        </div>
        <CRow>
            <CCol md={12} className='d-grid'>
                <CLoadingButton
                    size='lg'
                    type='submit'
                    form='loginform'
                    color='info'
                    loading={isSubmitting}
                >
                    <span className='ms-2'>Masuk Sebagai Admin</span>
                </CLoadingButton>
            </CCol>
        </CRow>
    </CForm>)
}
