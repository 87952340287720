
import {
    CCollapse,
    CContainer,
    CHeader,

    CNavbar,
    CNavbarBrand,
    CNavbarNav,
    CNavbarToggler,
    CNavItem,
    CNavLink,
} from '@coreui/react-pro'
import React, { useContext, useState } from 'react'
import { cilAccountLogout } from '@coreui/icons-pro'


import CIcon from "@coreui/icons-react";
import { Context } from "../../context";

const Header = () => {
    const [visible, setVisible] = useState(false)
    const { setVisibleModalLogout, user } = useContext(Context)

    return (
        <CHeader position="sticky" className="mb-4">
            <CNavbar colorScheme="light" expand="md" className="w-100 ">
                <CContainer fluid>
                    <CNavbarBrand href="#">{process.env.REACT_APP_PUBLIC_SITE_NAME}</CNavbarBrand>
                    <CNavbarToggler
                        aria-label="Toggle navigation"
                        aria-expanded={visible}
                        onClick={() => setVisible(!visible)}
                    />
                    <CCollapse className="navbar-collapse" visible={visible}>
                        <CNavbarNav className="me-auto">
                            {/*<CNavItem>*/}
                            {/*    <Link className={"nav-link"} to={{pathname : '/petunjuk'}}>Petunjuk</Link>*/}
                            {/*</CNavItem>*/}
                            {/*<CNavItem>*/}
                            {/*    <Link className={"nav-link"} to={{pathname : '/pertanyaan'}}>Pertanyaan</Link>*/}
                            {/*</CNavItem>*/}
                            <CNavItem>
                                <span className={'nav-link'}>{user?.email}</span>
                            </CNavItem>
                        </CNavbarNav>
                        <CNavbarNav className="d-flex">

                            <CNavItem>
                                <CNavLink
                                    className='text-danger'
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        console.log("asd")
                                        setVisibleModalLogout(true)
                                    }}
                                >
                                    <CIcon icon={cilAccountLogout} className="me-2" />
                                    Keluar
                                </CNavLink>
                            </CNavItem>
                        </CNavbarNav>
                    </CCollapse>
                </CContainer>
            </CNavbar>
        </CHeader>
    )
}


export default React.memo(Header)