export default function Page2({Question}: {Question: ({question, name}: {question: string, name: "q1" | "q2" | "q3" | "q4" | "q5" | "q6" | "q7" | "q8" | "q9" | "q10" | "q11" | "q12" | "q13" | "q14" | "q15" | "q16" | "q17" | "q18" | "q19" | "q20" | "q21" | "q22" | "q23" | "q24"}) =>    JSX.Element}){

    const questions: {name: string, question: string}[] = [
        {name: 'q7', question: 'Martabat dan kesejahteraan Masyarakat harus menjadi perhatian paling penting dalam Masyarakat manapun'},
        {name: 'q8', question: 'Tidak pernah perlu mengorbankan kesejahteraan orang lain'},
        {name: 'q9', question: 'Tindakan moral adalah tindakan yang sangat sesuai dengan cita-cita tindakan yang paling "sempurna"'},
    ]

    return (<>
        <ol start={7} style={{paddingLeft : 'inherit'}}>
            {questions.map((quest,key) => {
                return(<li key={key}>
                    <Question name={quest.name as 'q7' | 'q8' | 'q9'} question={quest.question} />
                </li>)
            })}
        </ol>

    </>)
}