import {
    CCard,
    CCardBody,
    CCardTitle, CCol,
    CForm, CImage,
    CLoadingButton, CRow
} from "@coreui/react-pro";
import {useContext, useState} from "react";
import api from "../../../../api";
import {GenericErrorHandler} from "../../../../../helpers";
import useToast from "../../../../../hooks/toast";
import {Context} from "../../../../../context";
import ImageInstruksi from "../../../../../assets/instruksi.svg"
export default function InstruksiPengerjaan(){
    const { reloadQuestionnaire } = useContext(Context);
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [addToast] = useToast();

    const onSubmit = async (e : React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoadingSubmit(true)
        await api.post('/answer', {section : 'instruksipengerjaan', answer: JSON.stringify(true)}).then(onfulfilled => {
            reloadQuestionnaire()
            addToast({color: 'success', message: 'Section submitted'})
        }).catch(reason => {
            console.log(reason, 'reason')
            GenericErrorHandler({reason, addToast})
        }).finally(() =>{
            setLoadingSubmit(false)
        })
    }

    return(<CCard>
        <CCardBody>
            <CCardTitle>Instruksi Pengerjaan</CCardTitle>
            <CRow>
                <CCol md={3}>
                    <CImage className={'w-100'} src={ImageInstruksi} />
                </CCol>
                <CCol md={9}>
                    <CForm
                        id='formSubmit'
                        onSubmit={onSubmit}
                    >
                        <p>Terima kasih telah mengisi data diri anda dan kuesioner awal penelitian</p>
                        <p>Sebelum melanjutkan, sebagai pengingat, Anda, sebagai CEO/CFO, nantinya diminta untuk menganalisis
                            kondisi keuangan perusahaan dan menentukan kebijakan perusahaan ke depannya.</p>
                    </CForm>
                </CCol>
            </CRow>
            <div className={'d-flex justify-content-end'}>
                <CLoadingButton disabled={loadingSubmit} loading={loadingSubmit} type={'submit'} form={'formSubmit'} className={'ms-2'}>
                    Selanjutnya
                </CLoadingButton>
            </div>
        </CCardBody>
    </CCard>)
}