import { useContext, useState} from 'react';
import {
    CButton, CLoadingButton,
    CModal,
    CModalBody, CModalFooter,
} from "@coreui/react-pro";

import useToast from "../../hooks/toast";
import {Context} from "../../context";
import api from "../api";

export function ModalLogout(){
    const [addToast] = useToast()
    const [loading, setLoading] = useState(false)
    const { visibleModalLogout, setVisibleModalLogout  } = useContext(Context)
    return (
        <CModal portal backdrop={true} size='sm' alignment='center' visible={visibleModalLogout} onClose={() => { setVisibleModalLogout(false) }}>
            <CModalBody>
                <>Anda yakin ingin keluar?</>
            </CModalBody>
            <CModalFooter>
                <CButton disabled={loading} color="secondary" onClick={() => { setVisibleModalLogout(false) }}>
                    Tidak
                </CButton>
                <CLoadingButton loading={loading} color='danger'
                    onClick={() => {
                        setLoading(true)
                        api.get(`/auth/logout`).then(onfulfilled => {

                        }).catch(() => {}).finally(() => {
                            addToast({ color: "success", message: "Logout berhasil" })
                            localStorage.clear()
                            setVisibleModalLogout(false)
                            setLoading(false)
                        })
                    }}
                >
                    Ya, Keluar!
                </CLoadingButton>
            </CModalFooter>
        </CModal>
    )

}