import axios from "axios";
import { IToken } from "../types/token";

const api = axios.create({
    baseURL: `${process.env.REACT_APP_PUBLIC_API_URL}client`
})

api.interceptors.request.use(
    function (config) {
        const token : IToken = JSON.parse(localStorage.getItem("token") || "{}");
        if (token) config.headers["Authorization"] = `Bearer ${token.token}`
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
export default api;