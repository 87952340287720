import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CCollapse,
    CLoadingButton,
    CRow
} from "@coreui/react-pro";
import {useContext, useEffect, useState} from "react";
import ScrollToTop from "react-scroll-to-top";
import Table from "./componenet.table";
import CIcon from "@coreui/icons-react";
import {cilArrowTop, cisChevronBottom, cisChevronTop, cisFileExcel} from "@coreui/icons-pro";
import {useSearchParams} from "react-router-dom";
import {Context} from "../../../context";
import FilterSorting from "./component.filter.sorting";
import api from "../../api";
import moment from "moment/moment";
import {GenericErrorHandler} from "../../../helpers";
import useToast from "../../../hooks/toast";


export default function  Index() {
    const { setMetaData } = useContext(Context)
    const [searchParams] = useSearchParams();
    const [collapseFilterSorting, setCollapseFilterSorting] = useState(searchParams.has('name') ||
        searchParams.has('group') ||
        searchParams.has('created_at_start') ||
        searchParams.has('created_at_end') ||
        searchParams.has('ordering')
    )
    const [loadingExport, setLoadingExport] = useState(false)
    const [addToast] = useToast()

    useEffect(() => {
        setMetaData({
            title: "Respondent",
        })
    }, [setMetaData])

    return (<>
        <CRow>
            <CCol md={12}>
                <CButton active={collapseFilterSorting} color={collapseFilterSorting ? 'dark' : 'primary'} size='sm' className='me-2 mb-2' onClick={() => {
                    setCollapseFilterSorting(x => !x)
                }}> Filter & Sorting <CIcon className='ms-1' icon={collapseFilterSorting ? cisChevronBottom : cisChevronTop} /></CButton>
                <CLoadingButton loading={loadingExport} disabled={loadingExport} size={"sm"} color={"primary"} className='me-2 mb-2'  onClick={() => {
                    if(loadingExport) return
                    setLoadingExport(true)
                    api.get('/respondentexport').then(onfullfilled => {
                        const file = new Blob([onfullfilled.data || ""], {type: 'text/csv'});

                        // anchor link
                        const element = document.createElement("a");
                        element.href = URL.createObjectURL(file);
                        element.download = `export_${moment().toISOString()}.csv`;

                        // simulate link click
                        document.body.appendChild(element); // Required for this to work in FireFox
                        element.click();
                        addToast({message: "Data berhasil terexport", color: "success" })
                    }).catch(reason => {
                        GenericErrorHandler({reason, addToast})
                    }).finally(() =>{
                        setLoadingExport(false)
                    });
                }} ><CIcon icon={cisFileExcel} className={'me-2'}/> Export</CLoadingButton>
            </CCol>
        </CRow>
        <CCollapse visible={collapseFilterSorting}>
            <CRow className='mb-3'>
                <CCol>
                    <CCard>
                        <CCardBody>
                            <FilterSorting />
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </CCollapse>
        <CRow>
            <CCol>
                <CCard>
                    <CCardBody>
                        <Table />
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <ScrollToTop smooth component={<CIcon icon={cilArrowTop} />} className='rounded-circle' />

    </>)
}
