import {useContext, useEffect, useState} from 'react';
import {
    CSpinner
} from "@coreui/react-pro";
import api from "../../api";
import {GenericErrorHandler} from "../../../helpers";
import useToast from "../../../hooks/toast";
import {IQuestionnaire} from "../../../types/entities/question";
import {Context} from "../../../context";
import Finished from "./finished";
import InformasiDiri from "./sections/informasidiri";
import MengukurKuesioner from "./sections/mengukurkuesioner";
import InstruksiPengerjaan from "./sections/instruksipengerjaan";
import Case from "./sections/case";



export function Questionnaire(){
    const { reloadQuestionnaireNotification } = useContext(Context);
    const [loading, setLoading] = useState(true)
    const [addToast] = useToast()
    const [questionnaire, setQuestionnaire] = useState<IQuestionnaire>()

    useEffect(() => {
        setLoading(true)
        api.get('/questionnaire').then(async onfulfilled => {
            const data: IQuestionnaire = onfulfilled.data
            setQuestionnaire(data)
            setLoading(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        })
    }, [addToast, reloadQuestionnaireNotification]);

    if(questionnaire?.finished){
        return (<Finished/>)
    }

    return(<>
        {loading && <CSpinner variant='border' />}
        {(!loading && questionnaire?.section === 'informasidiri') && <InformasiDiri />}
        {(!loading && questionnaire?.section === 'mengukurkuesioner') && <MengukurKuesioner />}
        {(!loading && questionnaire?.section === 'instruksipengerjaan') && <InstruksiPengerjaan />}
        {(!loading && questionnaire?.section === 'case') && <Case />}
    </>)
}
