import {
    CAlert,
    CButton,
    CCard,
    CCardBody,
    CCol,
    CPlaceholder,
    CRow
} from "@coreui/react-pro";
import {useContext, useEffect, useState} from "react";
import moment from "moment";
import CIcon from "@coreui/icons-react";
import {cilArrowTop, cilCloudDownload, cilCopy} from "@coreui/icons-pro";
import ScrollToTop from "react-scroll-to-top";
import api from "../../api";
import {GenericErrorHandler} from "../../../helpers";
import useToast from "../../../hooks/toast";
import {Context} from "../../../context";
import { IBackup } from "../../../types/entities/backup";

export default function Index(){
    const { setMetaData } = useContext(Context)
    const [addToast] = useToast()
    const [backup, setBackup] = useState<IBackup>()
    const [loadingBackup, setLoadingBackup] = useState(true)

    useEffect(() => {
        setMetaData({
            title: "Backup",
        })
    }, [setMetaData])

    useEffect(() => {
        api.get(`backup/database`).then(onfulfilled => {
            setBackup(onfulfilled.data)
            setLoadingBackup(false)
        }).catch(reason => {
            GenericErrorHandler({reason, addToast})
        });
    }, [addToast]);

    return (<>
        <CRow>
            <CCol>
                <CButton disabled={loadingBackup} size='sm' className='me-2 mb-2' onClick={e => {

                    // file object
                    const file = new Blob([backup?.result || ""], {type: 'text/plain'});

                    // anchor link
                    const element = document.createElement("a");
                    element.href = URL.createObjectURL(file);
                    element.download = `backup_${moment().toISOString()}.sql`;

                    // simulate link click
                    document.body.appendChild(element); // Required for this to work in FireFox
                    element.click();
                }}><CIcon icon={cilCloudDownload} className={'me-2'} /> Download</CButton>
                <CButton size={"sm"} className='me-2 mb-2' disabled={!backup?.result?.toString()} onClick={() => {
                    navigator.clipboard.writeText(backup?.result?.toString() || "").then(r => {
                        addToast({color:"success", message:"Coppied to clipboard"})
                    })

                }}><CIcon icon={cilCopy} className={'me-2'}/>Copy</CButton>
            </CCol>

        </CRow>
        <CRow>
            <CCol>
                <CCard className={'h-100'}>
                    <CCardBody >
                        {loadingBackup && <CPlaceholder animation="glow">
                            <CPlaceholder xs={12} size="lg" />
                        </CPlaceholder>}
                        {!loadingBackup && <>
                            <CAlert color={"info"} dismissible={true}>Last backup at <strong>{backup?.last_backup_at?.toString()}</strong></CAlert>
                            <code>
                                {backup?.result}
                            </code>
                        </>}
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <ScrollToTop smooth component={<CIcon icon={cilArrowTop} />} className='rounded-circle ' />
    </>)
}