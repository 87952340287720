
import {Route} from "react-router-dom";
import React from "react";
import Error404 from "./Error404";
import Login from "./login";
import App from "./App";
import {IRoute} from "../types/layout";
import Respondent from "./pages/respondent";
import RespondentDetail from "./pages/respondent/detail";
import Backup from "./pages/backup";

const routes: IRoute[] = [
    { path: `/`, name: 'Respondent', element: <Respondent /> },
    { path: `/respondent`, name: 'Respondent', element: <Respondent /> },
    { path: `/backup`, name: 'Backup', element: <Backup /> },
    { path: '/respondent/detail/:id', name: 'Detail Respondent', element: <RespondentDetail /> }
]

const AdminRoutes = [
    <Route path="/admin/login" element={
        <Login />
    } />,
    <Route path="/admin" element={
        <React.StrictMode>
            <App />
        </React.StrictMode>}>
        {routes.map((route, key) => {
            return (route.element && <Route
                key={key}
                path={`/admin${route.path}`}
                element={route.element}
            />)
        })}
        <Route path="*" element={<Error404 />} />
    </Route>
];

export default AdminRoutes