export default function Page3({Question}: {Question: ({question, name}: {question: string, name: "q1" | "q2" | "q3" | "q4" | "q5" | "q6" | "q7" | "q8" | "q9" | "q10" | "q11" | "q12" | "q13" | "q14" | "q15" | "q16" | "q17" | "q18" | "q19" | "q20" | "q21" | "q22" | "q23" | "q24"}) =>    JSX.Element}){

    const questions: {name: string, question: string}[] = [
        {name: 'q10', question: 'Mengingkari janji tidak pernah diperbolehkan secara moral, namun Anda harus mengingkarinya karena alasan pencapaian pekerjaan atau tugas'},
        {name: 'q11', question: 'Suatu tindakan pada hakikatnya bermoral hanya jika tindakan itu dilakukan dalam ketaatan pada suatu aturan'},
        {name: 'q12', question: 'Jika mengingkari janji akan menghasilkan lebih banyak kebaikan daripada keburukan, maka secara moral wajib mengingkari janji'},
        {name: 'q13', question: 'Jika ingkar janji akan memperburuk hasil, maka janji itu pelu ditepati'},
    ]

    return (<>
        <ol start={10} style={{paddingLeft : 'inherit'}}>
            {questions.map((quest,key) => {
                return(<li key={key}>
                    <Question name={quest.name as 'q10' | 'q11' | 'q12'| 'q13'} question={quest.question} />
                </li>)
            })}
        </ol>

    </>)
}