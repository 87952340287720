import {useContext} from "react";
import {Context} from "../../../../../context";
import {
    CAccordion,
    CAccordionBody,
    CAccordionHeader,
    CAccordionItem,
    CCol, CImage,
    CRow,
    CTable,
} from "@coreui/react-pro";
import ImageCase from '../../../../../assets/case.svg'

export default function CasePart2(){
    const { user } = useContext(Context);

    return (<>
        {user?.respondent?.group === 1 && <Group1/>}
        {user?.respondent?.group === 2 && <Group2/>}
        {user?.respondent?.group === 3 && <Group3/>}
        {user?.respondent?.group === 4 && <Group4/>}
    </>)
}

function Group1(){

    return (<>
        <CRow>
            <CCol md={3}>
                <CImage src={ImageCase} className={'w-100'} />
            </CCol>
            <CCol md={9}>
                <p>
                    Berdasarkan kondisi tersebut, anda, sebagai CEO Bank Ad&At,
                    diminta untuk menganalisis dan menentukan kebijakan
                    yang selanjutnya dapat berdampak pada laporan keuangan (laporan laba/rugi)
                    triwulan sebelum diserahkan kepada stakeholder.
                    Berikut disajikan beberapa alternatif draft laporan keuangan sebagai berikut:
                </p>
            </CCol>
        </CRow>
        <CAccordion>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 1</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                    <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>mengakui selisih hasil revaluasi tanah sebagai pendapatan.</li>
                            </ul>
                        </CCol>
                        <CCol md={12}>
                            <CTable striped={true} hover={true} bordered={true} items={[
                                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan lain-lain', c2: '', c3: '64.000'},
                                {c1: 'Beban bunga dana', c2: '13.950', c3: ''},
                                {c1: 'Beban operasi bisnis', c2: '31.675', c3: ''},
                                {c1: <strong>Ekspektasi profit margin menggunakan minimum (3%)</strong>, c2: <strong>9.350</strong>, c3: ''},
                                {c1: 'Total biaya dikeluarkan', c2: '', c3: '45.625'},
                                {c1: 'EBIT', c2: '', c3: <span className={'text-success'}>63.250</span>},
                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 2</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>memperbaharui pencatatatan atas selisih hasil revaluasi tanah yang ada pada pos aset tanah.</li>
                            </ul>
                        </CCol>
                        <CCol md={12}>
                            <CTable striped={true} hover={true} bordered={true} items={[
                                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan bunga dana', c2: '13.950', c3: ''},
                                {c1: 'Beban operasional bisnis', c2: '31.675', c3: ''},
                                {c1: 'Beban non-operasional (jasa KJPP)', c2: '15', c3: ''},
                                {c1: <strong>Ekspektasi profit margin menggunakan minimum (3%)</strong>, c2: <strong>9.300</strong>, c3: ''},
                                {c1: 'Total biaya dikeluarkan', c2: '', c3: '45.640'},
                                {c1: 'EBIT', c2: '', c3: <span className={'text-danger'}>-765</span>},
                            ]} />
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 3</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>Mengakui selisih hasil revaluasi tanah sebagai pendapatan.</li>
                                <li>Melakukan perjanjian Build-Operate-Transfer (BOT) dengan anak perusahaan yang bergerak di bidang jasa building and management, PT. Ad Karya, yang kedepannya dapat disewakan kepada Ad-At-Finance.</li>
                            </ul>
                        </CCol>
                        <CCol md={12}>
                            <CTable striped={true} hover={true} bordered={true} items={[
                                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan lain-lain', c2: '', c3: '64.000'},
                                {c1: 'Beban bunga dana', c2: '13.950', c3: ''},
                                {c1: 'Beban operasi bisnis', c2: '31.675', c3: ''},
                                {c1: <strong>Ekspektasi profit margin menggunakan minimum (3%)</strong>, c2: <strong>9.350</strong>, c3: ''},
                                {c1: 'Total biaya dikeluarkan', c2: '', c3: '45.625'},
                                {c1: 'EBIT', c2: '', c3: <span className={'text-success'}>63.250</span>},
                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 4</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>Mengakui selisih hasil revaluasi tanah sebagai pendapatan agar sesuai target capaian margin.</li>
                                <li>Melakukan perjanjian Build-Operate-Transfer (BOT) dengan anak perusahaan yang bergerak di bidang jasa building and management, PT. Ad Karya, yang kedepannya dapat disewakan kepada Ad-At-Finance.</li>
                            </ul>
                        </CCol>
                        <CTable striped={true} hover={true} bordered={true} items={[
                            {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                            {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                            {c1: 'Pendapatan lain-lain', c2: '', c3: '16.000'},
                            {c1: 'Beban bunga dana', c2: '13.950', c3: ''},
                            {c1: 'Beban operasi bisnis', c2: '31.675', c3: ''},
                            {c1: <strong>Ekspektasi profit margin menggunakan minimum (3%)</strong>, c2: <strong>9.350</strong>, c3: ''},
                            {c1: 'Total biaya dikeluarkan', c2: '', c3: '45.625'},
                            {c1: 'EBIT', c2: '', c3: <span className={'text-success'}>15.250</span>},
                        ]}/>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
        </CAccordion>
    </>)
}

function Group2(){

    return (<>
        <CRow>
            <CCol md={3}>
                <CImage src={ImageCase} className={'w-100'} />
            </CCol>
            <CCol md={9}>
                <p>Berdasarkan kondisi tersebut, anda, sebagai <strong>CEO Bank Ad&amp;At</strong>, diminta untuk
                    menganalisis
                    dan menentukan kebijakan yang selanjutnya dapat berdampak pada laporan keuangan (laporan laba/rugi)
                    triwulan-II
                    sebelum diserahkan kepada stakeholder. <strong>Berikut disajikan beberapa alternatif draft laporan
                        keuangan
                        sebagai berikut:</strong></p>
            </CCol>
        </CRow>

        <CAccordion>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 1</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>membayar seluruh utang dan biaya BPJS dan asuransi purna jabatan,</li>
                                <li>serta mengakui aktual nilai pendapatan kerjasama “literasi keuangan nasional” di tahun ini</li>
                            </ul>
                        </CCol>
                        <CCol md={12}>
                            <CTable striped={true} hover={true} bordered={true} items={[
                                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan non bunga', c2: '', c3: '5.400'},
                                {c1: 'Beban bunga dana', c2: '13.950', c3: ''},
                                {c1: 'Beban operasi bisnis', c2: '32.351', c3: ''},
                                {c1: 'Beban non-operasional', c2: '1.225', c3: ''},
                                {c1: <strong>Ekspektasi profit margin - Akumulasi (5.5%)</strong>, c2: <strong>17.050</strong>, c3: ''},
                                {c1: 'Total beban dikeluarkan', c2: '', c3: '47.526'},
                                {c1: 'EBIT', c2: '', c3: <span className={'text-success'}>2.749</span>},
                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 2</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>menunda seluruh utang biaya BPJS dan asuransi purna jabatan,</li>
                                <li>serta mengakui seluruh nilai pendapatan kerjasama di tahun ini</li>
                            </ul>
                        </CCol>
                        <CCol md={12}>
                            <CTable striped={true} hover={true} bordered={true} items={[
                                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan non bunga', c2: '', c3: '27.000'},
                                {c1: 'Beban bunga dana', c2: '13.950', c3: ''},
                                {c1: 'Beban operasi bisnis', c2: '31.675', c3: ''},
                                {c1: 'Beban non-operasional', c2: '17.050', c3: ''},
                                {c1: <strong>Ekspektasi profit margin - Akumulasi (5.5%)</strong>, c2: <strong>45.625</strong>, c3: ''},
                                {c1: 'Total beban dikeluarkan', c2: '', c3: '47.526'},
                                {c1: 'EBIT', c2: '', c3: <span className={'text-success'}>26.250</span>},
                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 3</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>menunda seluruh pembayaran utang biaya BPJS dan asuransi purna jabatan,
                                    serta mengakui seluruh nilai pendapatan kerjasama di tahun ini
                                </li>
                                <li>para top management menerima bonus insentif karena telah mencapai target ketertinggalan profit margin dari periode sebelumnya, yakni 1% dari target profit margin
                                </li>
                            </ul>
                        </CCol>
                        <CCol md={12}>
                            <CTable striped={true} hover={true} bordered={true} items={[
                                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan non bunga', c2: '', c3: '27.000'},
                                {c1: 'Beban bunga dana', c2: '13.950', c3: ''},
                                {c1: 'Beban operasi bisnis (ioerasional + biaya direksi dan komisaris)', c2: '31.845', c3: ''},
                                {c1: 'Beban non-operasional', c2: '-', c3: ''},
                                {c1: <strong>Ekspektasi profit margin - Akumulasi (5.5%)</strong>, c2: <strong>17.050</strong>, c3: ''},
                                {c1: 'Total beban dikeluarkan', c2: '', c3: '45.625'},
                                {c1: 'EBIT', c2: '', c3: <span className={'text-success'}>26.079</span>},
                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 4</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>membayar sebagian utang biaya BPJS dan asuransi purna jabatan (tunggakan tahun lalu),
                                </li>
                                <li>serta mengakui nilai pendapatan aktual atas kerjasama “literasi keuangan nasional” di tahun ini
                                </li>
                            </ul>
                        </CCol>
                        <CCol md={12}>
                            <CTable striped={true} hover={true} bordered={true} items={[
                                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan non bunga', c2: '', c3: '5.400'},
                                {c1: 'Beban bunga dana', c2: '13.950', c3: ''},
                                {c1: 'Beban operasi bisnis', c2: '31.675', c3: ''},
                                {c1: <strong>Ekspektasi profit margin menggunakan minimum (5.5%)</strong>, c2: <strong>17.050</strong>, c3: ''},
                                {c1: 'Total beban dikeluarkan', c2: '', c3: '45.625'},
                                {c1: 'EBIT', c2: '', c3: <span className={'text-success'}>4.650</span>},
                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
        </CAccordion>
    </>)
}


function Group3(){


        return (<>
            <CRow>
                <CCol md={3}>
                    <CImage src={ImageCase} className={'w-100'} />
                </CCol>
                <CCol md={9}>
                    <p>Berdasarkan kondisi tersebut, anda, sebagai <strong>CEO Bank Ad&amp;At</strong>, diminta untuk
                        menganalisis
                        dan menentukan kebijakan yang selanjutnya dapat berdampak pada laporan keuangan (laporan laba/rugi)
                        triwulan-II
                        sebelum diserahkan kepada stakeholder. <strong>Berikut disajikan beberapa alternatif draft
                            laporan keuangan
                            sebagai berikut:</strong></p>
                </CCol>
            </CRow>

            <CAccordion>
                <CAccordionItem>
                    <CAccordionHeader><strong>Kebijakan 1</strong></CAccordionHeader>
                    <CAccordionBody>
                        <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>mengakui seluruh nilai kontrak atas kerjasama digitasi bank sebagai pendapatan
                                </li>
                                <li>membayarkan insentif bonus yang tertunda sebesar 1M yang terbatas kepada top management & bonus atas capaian target margin pada periode pelaporan ini (1% dari target margin).
                                </li>
                            </ul>
                        </CCol>
                            <CCol md={12}>
                                <CTable striped={true} hover={true} bordered={true} items={[
                                    {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan lain-lain', c2: '', c3: '15.000'},
                                {c1: 'Beban bunga dana', c2: '13.950', c3: ''},
                                {c1: 'beban operasi bisnis (operasional + bonus top manajemen yang tertenda + bonus target margin)', c2: '32.768', c3: ''},
                                {c1: <strong>Ekspektasi profit margin menggunakan minimum (3%)</strong>, c2: <strong>9.300</strong>, c3: ''},
                                {c1: 'Total beban dikeluarkan', c2: '', c3: '46.718'},
                                {c1: 'EBIT', c2: '', c3: <span className={'text-success'}>13.157</span>},

                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 2</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>mengakui up-front fee 20% atas kerjasama digitasi bank dan membayarkan seluruh insentif bonus.
                                </li>
                                <li>Membayarkan seluruh insentif bonus yang tertunda
                                </li>
                            </ul>
                        </CCol>
                        <CCol md={12}>
                            <CTable striped={true} hover={true} bordered={true} items={[
                                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan lain-lain', c2: '', c3: '3.000'},
                                {c1: 'Beban bunga dana', c2: '13.950', c3: ''},
                                {c1: 'beban operasi bisnis', c2: '40.675', c3: ''},
                                {c1: <strong>Ekspektasi profit margin menggunakan minimum (3%)</strong>, c2: <strong>9.300</strong>, c3: ''},
                                {c1: 'Total biaya dikeluarkan', c2: '', c3: '54.625'},
                                {c1: 'EBIT', c2: '', c3: <span className={'text-danger'}>-6.750</span>},
                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 3</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>mengakui seluruh nilai kontrak atas kerjasama digitasi bank sebagai pendapatan</li>
                                <li>menunda kembali atas rencana pembagian insentif bonus yang tertunda 2 tahun terakhir ini, tetapi membayarkan bonus atas capaian target margin (1%) pada periode pelaporan ini.
                                </li>
                            </ul>
                        </CCol>
                        <CCol md={12}>
                            <CTable striped={true} hover={true} bordered={true} items={[
                                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan lain-lain', c2: '', c3: '15.000'},
                                {c1: 'Beban bunga dana', c2: '13.950', c3: ''},
                                {c1: 'Beban operasional bisnis', c2: '31.768', c3: ''},
                                {c1: <strong>Ekspektasi profit margin menggunakan minimum (3%)</strong>, c2: <strong>9.300</strong>, c3: ''},
                                {c1: 'Total biaya dikeluarkan', c2: '', c3: '45.718'},
                                {c1: 'EBIT', c2: '', c3: '14.157'},
                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 4</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>mengakui up-front fee 20% atas kerjasama digitasi bank dan membayarkan seluruh insentif bonus.
                                </li>
                                <li>membayarkan insentif bonus yang tertunda sebesar 1M kepada seluruh lapisan karyawan sesuai dengan ketentuan/aturan perusahaan yang berlaku
                                </li>
                            </ul>
                        </CCol>
                        <CCol md={12}>
                            <CTable striped={true} hover={true} bordered={true} items={[
                                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan lain-lain', c2: '', c3: '3000'},
                                {c1: 'Biaya bunga dana', c2: '13.950', c3: ''},
                                {c1: 'Biaya operasional bisnis', c2: '32.675', c3: ''},
                                {c1: 'Ekspektasi profit margin menggunakan minimum', c2: <strong>9.300</strong>, c3: ''},
                                {c1: 'Total biaya dikeluarkan', c2: '', c3: '46.625'},
                                {c1: 'EBIT', c2: '', c3: '1.250'},
                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
        </CAccordion>
    </>)

}

function Group4(){

        return (<>
            <CRow>
                <CCol md={3}>
                    <CImage src={ImageCase} className={'w-100'} />
                </CCol>
                <CCol md={9}>
                    <p>Berdasarkan kondisi tersebut, anda, sebagai <strong>CEO Bank Ad&amp;At</strong>, diminta untuk
                        menganalisis
                        dan menentukan kebijakan yang selanjutnya dapat berdampak pada laporan keuangan (laporan laba/rugi)
                        triwulan-II
                        sebelum diserahkan kepada stakeholder. <strong>Berikut disajikan beberapa alternatif draft
                            laporan keuangan
                            sebagai berikut:</strong></p>
                </CCol>
            </CRow>

            <CAccordion>
                <CAccordionItem>
                    <CAccordionHeader><strong>Kebijakan 1</strong></CAccordionHeader>
                    <CAccordionBody>
                        <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>mengakui sharing profit 2% sebagai pendapatan</li>
                                <li>menunda kembali seluruh tagihan dan tunggakan biaya (jasa outsourching & BPJS karyawan non-organik) yang belum terbayarkan
                                </li>
                            </ul>
                        </CCol>
                            <CCol md={12}>
                                <CTable striped={true} hover={true} bordered={true} items={[
                                    {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan non bunga', c2: '', c3: '12.000'},
                                {c1: 'Biaya bunga dana', c2: '13.950', c3: ''},
                                {c1: 'Biaya operasional bisnis', c2: '31.675', c3: ''},
                                {c1: <strong>Ekspektasi profit margin - Akumulasi (3%)</strong>, c2: <strong>9.300</strong>, c3: ''},
                                {c1: 'Total biaya dikeluarkan', c2: '', c3: '45.625'},
                                {c1: 'EBIT', c2: '', c3: <span className={'text-success'}>11.250</span>},
                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 2</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>mengakui sharing profit 2% sebagai pendapatan
                                    menunda kembali seluruh tagihan dan tunggakan biaya (jasa outsourching & BPJS karyawan non-organik) yang belum terbayarkan
                                </li>
                                <li>para top management menerima bonus insentif karena telah mencapai target ketertinggalan profit margin dari periode sebelumnya, yakni 1,5% dari target profit margin
                                </li>
                            </ul>
                        </CCol>
                        <CCol md={12}>
                            <CTable striped={true} hover={true} bordered={true} items={[
                                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan non bunga', c2: '', c3: '12.000'},
                                {c1: 'Pendapatan bunga dana', c2: '13.950', c3: ''},
                                {c1: 'Biaya operasional bisnis (operasional + bonus target margin)', c2: '31.814', c3: ''},
                                {c1: <strong>Ekspektasi profit margin - Akumulasi (3%)</strong>, c2: <strong>9.300</strong>, c3: ''},
                                {c1: 'Total biaya dikeluarkan', c2: '', c3: '45.764'},
                                {c1: 'EBIT', c2: '', c3: <span className={'text-success'}>11.110</span>},
                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 3</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>Mengakui sharing profit atas kerjasama tersebut sebagaimana yang telah diterima sebagai pendapatan (cash-in) secara actual
                                </li>
                                <li>Membayar seluruh tagihan dan tunggakan biaya (jasa outsourching & BPJS karyawan non-organik) yang belum terbayarkan
                                </li>
                            </ul>
                        </CCol>
                        <CCol md={12}>
                            <CTable striped={true} hover={true} bordered={true} items={[
                                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan non bunga', c2: '', c3: '1.800'},
                                {c1: 'Biaya bunga dana', c2: '13.950', c3: ''},
                                {c1: 'Biaya operasional bisnis', c2: '33.792', c3: ''},
                                {c1: <strong>Ekspektasi profit margin - Akumulasi (3%)</strong>, c2: <strong>9.300</strong>, c3: ''},
                                {c1: 'Total biaya dikeluarkan', c2: '', c3: '47.742'},
                                {c1: 'EBIT', c2: '', c3: <span className={'text-danger'}>-1.067</span>},
                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem>
                <CAccordionHeader><strong>Kebijakan 4</strong></CAccordionHeader>
                <CAccordionBody>
                    <CRow>
                        <CCol md={12}>
                            <strong>Hasil kebijakan dari:</strong>
                            <ul>
                                <li>mengakui sharing profit 2% sebagai pendapatan
                                </li>
                                <li>membayar tunggakan biaya (BPJS karyawan non-organik) tahun lalu yang belum terbayarkan
                                </li>
                            </ul>
                        </CCol>
                        <CCol md={12}>
                            <CTable striped={true} hover={true} bordered={true} items={[
                                {c1: 'Total Deposit', c2: '', c3: '310.000 (dalam jutaan Rupiah)'},
                                {c1: 'Pendapatan bunga kredit', c2: '', c3: '44.875'},
                                {c1: 'Pendapatan non bunga', c2: '', c3: '1.800'},
                                {c1: 'Biaya bunga dana', c2: '13.950', c3: ''},
                                {c1: 'Biaya operasional bisnis', c2: '33.792', c3: ''},
                                {c1: <strong>Ekspektasi profit margin - Akumulasi (3%)</strong>, c2: <strong>9.300</strong>, c3: ''},
                                {c1: 'Total biaya dikeluarkan', c2: '', c3: '47.742'},
                                {c1: 'EBIT', c2: '', c3: <span className={'text-danger'}>-1.067</span>},
                            ]}/>
                        </CCol>
                    </CRow>
                </CAccordionBody>
            </CAccordionItem>
        </CAccordion>
    </>)
}